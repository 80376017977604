import React, { FC } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  title: string;
  description?: string;
  lang?: string;
  pathname?: string;
  meta?: [any];
}

interface QueryData {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: {
        name: string,
      },
      keywords: string;
      siteUrl: string;
      social: {
        vk: string;
        facebook: string;
      };
    };
  };
}

export const Seo: FC<SEOProps> = ({ description = '', lang = 'ru', meta = [], title, pathname }) => {
  const { site: {siteMetadata} } = useStaticQuery<QueryData>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
            social {
              vk
              facebook
            }
          }
        }
      }
    `
  )

  const metaDescription = description || siteMetadata.description
  const defaultTitle = siteMetadata.title
  const canonical = pathname ? `${siteMetadata.siteUrl}${pathname}` : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      link={
        canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
            ]
            : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `facebook:card`,
          content: `summary`,
        },
        {
          name: `facebook:creator`,
          content: siteMetadata.social.facebook,
        },
        {
          name: `facebook:title`,
          content: title,
        },
        {
          name: `facebook:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: siteMetadata.keywords,
        },
        {
          name: 'google-site-verification',
          content: 'RaRl2A77mKlUp5sqxBMsD1NZhvr9LtE1rYA41aUjjZI'
        }
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://fairy-tales.netlify.app",
          "name": "Волшебные сказки для детей",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "kizilov.yura@gmail.com",
            "contactType": "Служба поддержки"
          }
        }
      `}
      </script>
    </Helmet>
  )
}

