import React, { useState } from 'react';
import * as JsSearch from 'js-search';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { AuthorData, CategoryData, ImagesTaleData, TextTaleData } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { CardContent, Card } from '@material-ui/core';

interface SearchQueryFields {
    _id: number;
    name: string;
    slug: string;
}

type SearchQueryDataType<T extends SearchQueryFields> = Pick<T, keyof SearchQueryFields>

// TODO: find why this isn't work
// interface SearchQueryDataFieldType<T extends SearchQueryFields> {
//     nodes: SearchQueryDataType<T>[];
// }

interface SearchQueryData {
    allAuthors: {
        nodes: SearchQueryDataType<AuthorData>[];
    }
    allCategories: {
        nodes: SearchQueryDataType<CategoryData>[];
    }
    allTextTales: {
        nodes: SearchQueryDataType<TextTaleData>[];
    }
    allImagesTales: {
        nodes: SearchQueryDataType<ImagesTaleData>[];
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        width: '100%'
    },
    input: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 4,
        boxShadow: 'inset 0 0 5px #aaa',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    iconButton: {
        padding: 10
    },
    autocomplete: {
        position: 'absolute',
        top: '100%',
        width: '350px',
        maxHeight: '450px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        background: '#fff',
        zIndex: 2,
        right: 25,
        [theme.breakpoints.down('xs')]: {
            width: '250px',
            maxHeight: '300px'
        }
    }
}));

export const Search = () => {
    const classes = useStyles();
    const { allAuthors, allCategories, allTextTales, allImagesTales } = useStaticQuery<SearchQueryData>(graphql`
        query getSearchDataQuery {
          allAuthors {
            nodes {
              name
              _id
              slug
            }
          }
          allCategories {
            nodes {
              _id
              name
              slug
            }
          }
          allTextTales {
            nodes {
              _id
              name
              slug
            }
          }
          allImagesTales {
            nodes {
              _id
              name
              slug
            }
          }
        }
    `);
    const [authorsList, setAuthorsList] = useState<SearchQueryFields[]>([]);
    const [textTalesList, setTextTalesList] = useState<SearchQueryFields[]>([]);
    const [imagesTalesList, setImagesTalesList] = useState<SearchQueryFields[]>([]);
    const [categoriesList, setCategoriesList] = useState<SearchQueryFields[]>([]);
    const [isListVisible, setIsListVisible] = useState(false);
    const [value, setValue] = useState('');

    const textTalesSearch = new JsSearch.Search('_id');
    textTalesSearch.addIndex('name');
    textTalesSearch.addDocuments(allTextTales.nodes);
    const imagesTalesSearch = new JsSearch.Search('_id');
    imagesTalesSearch.addIndex('name');
    imagesTalesSearch.addDocuments(allImagesTales.nodes);
    const authorsSearch = new JsSearch.Search('_id');
    authorsSearch.addIndex('name');
    authorsSearch.addDocuments(allAuthors.nodes);
    const categoriesSearch = new JsSearch.Search('_id');
    categoriesSearch.addIndex('name');
    categoriesSearch.addDocuments(allCategories.nodes);


    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        const imagesTalesResult = imagesTalesSearch.search(event.target.value);
        const textTalesResult = textTalesSearch.search(event.target.value);
        const authorsResult = authorsSearch.search(event.target.value);
        const categoriesResult = categoriesSearch.search(event.target.value);
        setImagesTalesList(imagesTalesResult as SearchQueryFields[]);
        setTextTalesList(textTalesResult as SearchQueryFields[]);
        setAuthorsList(authorsResult as SearchQueryFields[]);
        setCategoriesList(categoriesResult as SearchQueryFields[]);
        if (event.target.value === '') {
            setIsListVisible(false);
        } else {
            setIsListVisible(true);
        }
    };
    const onBlurHandler = () => {
        setTimeout(() => {
            setIsListVisible(false);
        }, 600);
    };
    const onFocusHandler = () => {
        if (value !== '') {
            setIsListVisible(true);
        }
    };

    const onItemClickHandler = () => {
        setIsListVisible(false);
    };

    return (
        <div className={classes.root}>
            <InputBase
                className={classes.input}
                placeholder='Поиск'
                value={value}
                inputProps={{ 'aria-label': 'Поиск' }}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                onFocus={onFocusHandler}
            />
            {isListVisible && (
                <Card className={classes.autocomplete}>
                    <CardContent>
                        {!!textTalesList.length && (
                            <div className='autocomplete-block'>
                                <h3>Сказки</h3>
                                {textTalesList.map((item) => (
                                    <Link to={`/text-tales/${item.slug}`} key={item._id}
                                          onClick={() => onItemClickHandler()}>{item.name}</Link>
                                ))}
                            </div>
                        )}
                        {!!imagesTalesList.length && (
                            <div className='autocomplete-block'>
                                <h3>Сканы сказок</h3>
                                {imagesTalesList.map((item) => (
                                    <Link to={`/images-tales/${item.slug}`} key={item._id}
                                          onClick={() => onItemClickHandler()}>{item.name}</Link>
                                ))}
                            </div>
                        )}
                        {!!authorsList.length && (
                            <div className='autocomplete-block'>
                                <h3>Авторы</h3>
                                {authorsList.map((item) => (
                                    <Link to={`/authors/${item.slug}`} key={item._id}
                                          onClick={() => onItemClickHandler()}>{item.name}</Link>
                                ))}
                            </div>
                        )}
                        {!!categoriesList.length && (
                            <div className='autocomplete-block'>
                                <h3>Категории</h3>
                                {categoriesList.map((item) => (
                                    <Link to={`/categories/${item._id}`} key={item._id}
                                          onClick={() => onItemClickHandler()}>{item.name}</Link>
                                ))}
                            </div>
                        )}
                        {!authorsList.length && !textTalesList.length && !categoriesList.length && !imagesTalesList.length &&
                        <p>Ничего не найдено</p>}
                    </CardContent>
                </Card>
            )}
        </div>
    );
};
