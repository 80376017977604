import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import { Search } from './Search';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    toolbarTitle: {
        textDecoration: 'none',
        color: '#000',
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    logoText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        fontWeight: 400,
    },
    logoImg: {
        height: 40,
        width: 50,
        marginRight: 20
    }
}));

interface HeaderProps {
    isRootPath: boolean;
}

export const Header = ({ isRootPath }: HeaderProps) => {
    const classes = useStyles();
    let header;

    if (isRootPath) {
        header = (
            <div className={classes.logo}>
                <img className={classes.logoImg} src="/icons/logo.png" alt="logo"/>
                <Typography
                    component="p"
                    variant="h6"
                    color="inherit"
                    align="center"
                    className={classes.logoText}
                    noWrap
                >
                    Сказочник
                </Typography>
            </div>
        )
    } else {
        header = (
            <Link to='/' className={classes.toolbarTitle}>
                <div className={classes.logo}>
                    <img className={classes.logoImg} src="/icons/logo.png" alt="logo"/>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        align="center"
                        className={classes.logoText}
                        noWrap
                    >
                        Сказочник
                    </Typography>
                </div>
            </Link>
        )
    }

    return (
        <React.Fragment>
            <Toolbar className={classes.toolbar}>
                {header}
                <Search />
            </Toolbar>
        </React.Fragment>
    );
}