import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { AuthorData, CategoryData } from '../types';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
        display: 'flex',
        flexDirection: 'column'
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
    link: {
        marginBottom: 10,
        fontSize: 16,
    },
    divider: {
        margin: theme.spacing(3),
    }
}));

interface SidebarQueryData {
    allCategories: {
        nodes: Pick<CategoryData, 'slug' | 'name'>[]
    };
    allAuthors: {
        nodes: Pick<AuthorData, 'slug' | 'name'>[]
    };
}

export const Sidebar = () => {
    const classes = useStyles();
    const { allCategories, allAuthors } = useStaticQuery<SidebarQueryData>(graphql`
        query getSidebarDataQuery {
          allCategories(limit: 10) {
            nodes {
              slug
              name
            }
          }
          allAuthors(limit: 10) {
            nodes {
              slug
              name
            }
          }
        }
    `);

    return (
        <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
                Популярные категории
            </Typography>
            <Paper elevation={0} className={classes.sidebarAboutBox}>
                {allCategories.nodes.map(({ name, slug }) => (
                    <Link key={slug} className={classes.link} to={`/categories/${slug}`}>{name}</Link>
                ))}
            </Paper>
            <Divider className={classes.divider} />
            <Typography variant="h6" gutterBottom>
                Популярные авторы
            </Typography>
            <Paper elevation={0} className={classes.sidebarAboutBox}>
                {allAuthors.nodes.map(({ name, slug }) => (
                    <Link key={slug} className={classes.link} to={`/authors/${slug}`}>{name}</Link>
                ))}
            </Paper>
        </Grid>
    );
}
