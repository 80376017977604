import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import StyledLink from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';

function Copyright({siteUrl, siteName}: {siteName?: string, siteUrl?: string}) {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={siteUrl || '#'}>
                {siteName || 'Your Website'}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


interface HeaderSection {
    title: string;
    url: string;
}

const sections: HeaderSection[] = [
    { title: 'Сказки', url: '/tales/all' },
    { title: 'Авторы', url: '/authors' },
    { title: 'Категории', url: '/categories' },
    { title: 'Карта сайта', url: '/sitemap' },
];

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 0),
    },
    toolbarSecondary: {
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    toolbarLink: {
        padding: theme.spacing(2),
        flexShrink: 0,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            fontSize: 16,
        },
    },
}));

export const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                    {sections.map((section) => (
                        <StyledLink
                            color="inherit"
                            noWrap
                            key={section.title}
                            variant="h6"
                            href={section.url}
                            className={classes.toolbarLink}
                        >
                            {section.title}
                        </StyledLink>
                    ))}
                </Toolbar>
                <Copyright siteName='Волшебные сказки' siteUrl='https://fairy-tales.netlify.app/' />
            </Container>
        </footer>
    );
}