import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Seo } from './Seo';
import { Header } from './Header';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';

interface LayoutProps {
    location: PageProps['location'];
    seoTitle: string;
    withoutSidebar?: boolean;
}

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% + 36px)'
        }
    }
}));

export const Layout: FC<LayoutProps> = ({
    location,
    seoTitle,
    children,
    withoutSidebar= false,
}) => {
    const rootPath = `${__PATH_PREFIX__}/`;
    const isRootPath = location.pathname === rootPath;
    const classes = useStyles();

    return (
        <>
            <Seo title={seoTitle} pathname={location.pathname}/>
            <CssBaseline/>
            <Container maxWidth="lg">
                <Header isRootPath={isRootPath}/>
                <main>
                    <Grid container spacing={5} className={classes.mainGrid}>
                        <Grid item xs={12} md={withoutSidebar ? 12 : 8}>
                            {children}
                        </Grid>
                        {!withoutSidebar && <Sidebar/>}
                    </Grid>
                </main>
            </Container>
            <Footer/>
        </>
    );
};

export default Layout;
